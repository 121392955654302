import axios from 'axios';
import { resetStore } from 'store/auth/authDataSlice';
import { actUrl, adminUrl, benchmarkUrl } from "constant"

const CryptoJS = require("crypto-js");
export const baseURL = process.env.REACT_APP_BASE_URL;
export const baseURLLocal = process.env.REACT_APP_BASE_URL_ADMIN;

export const imageURL = process.env.REACT_APP_BASE_URL_ASSET;
export const imageToken = process.env.REACT_APP_BASE_URL_ASSET_TOKEN
const currentExecutingRequests: any = {};

/**
 * Define interceptor to handle api resoponse and set header value
 */
const InterceptorApi = (store: any) => {

    axios.interceptors.request.use(function (req: any) {
        let originalRequest: any = req;
        if (!originalRequest?.headers?.deniedCancle && currentExecutingRequests[originalRequest.url]) {
            const source = currentExecutingRequests[originalRequest.url];
            delete currentExecutingRequests[originalRequest.url];
            source.cancel();
        }

        if (originalRequest?.url !== `${actUrl}save-url` && originalRequest?.url !== process.env.REACT_APP_FUNCTIONAL_URL && originalRequest?.url !== process.env.REACT_APP_BASE_URL_CHARTBOAT) {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            originalRequest.cancelToken = source.token;
            currentExecutingRequests[originalRequest.url] = source;

        }
        // here you could add the authorization header to the request
        const token = store.getState()?.auth?.loginDetails?.data?.token
        if (token && originalRequest?.headers?.isOpenedKey !== "true") {
            originalRequest.headers.Authorization = `Bearer ${token}`
        }

        // return originalRequest;
        const url = (`${originalRequest?.url?.split("/")[0]}/` === adminUrl || `${originalRequest?.url?.split("/")[0]}/` === benchmarkUrl) ? baseURLLocal : baseURL

        return { ...originalRequest, baseURL: url };
    }, (error: any) => {
        return Promise.reject(error);
    })
    axios.interceptors.response.use((response: any) => {
        if (response?.data && response.request?.responseURL !== process.env.REACT_APP_FUNCTIONAL_URL && response.request?.responseURL !== process.env.REACT_APP_BASE_URL_CHARTBOAT && !response.request?.responseURL.includes("ev-scac-excel")) {
            const bytes = CryptoJS.AES.decrypt(response?.data?.data, process.env.REACT_APP_EN_KEY);
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return { ...response, data: { ...response.data, data: decryptedData } };
        }
        else {
            return response
        }
    }, (error: any) => {
        if (error?.response?.data?.authorized === false) {

            for (const [key] of Object.entries(currentExecutingRequests)) {
                const source = currentExecutingRequests[key];
                delete currentExecutingRequests[key];
                source.cancel();
            }

            store.dispatch(resetStore())
        }
        return Promise.reject(error);
    })
}
export default InterceptorApi