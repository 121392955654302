import axios from "axios";
import { scope1 } from "constant"


// Fetch scope1 dashboard matrics data
const getDashboardMatrics = async (userData: any) => {
  try {
    const response = await axios.get(`${scope1}matrix-data?year=${userData?.year}&period_id=${userData?.periodId}`);
    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

const fuelConsumptionGraphApi = async (userData: any) => {
  try {
    const response = await axios.get(`${scope1}fuel-consumption-graph?year=${userData?.year}&period_id=${userData?.periodId}`);
    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

const fuelEmissionsGraphApi = async (userData: any) => {
  try {
    const response = await axios.get(`${scope1}fuel-emissions-graph?year=${userData?.year}&period_id=${userData?.periodId}`);
    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

// Service object that exposes functions
const dashboardService = {
  getDashboardMatrics,
  fuelConsumptionGraphApi,
  fuelEmissionsGraphApi
};

// Export the service object
export default dashboardService;
