import { configureStore } from '@reduxjs/toolkit';
import authDataReducer from "./auth/authDataSlice";
import homeReducer from "./home/homeSlice";
import { logger } from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';


import commonDataReducer from './commonData/commonSlice';
import regionDataReducer from './region/regionSlice';
import laneDetailsReducer from './lane/laneDetailsSlice';
import regionOverviewReducer from './region/regionOverviewSlice';
import sustainDataReducer from './sustain/sustainSlice';
import dashRegionReducer from './dashRegion/dashRegionSlice';
import carrierDetailsReducer from './carrier/vendorSlice';
import facilityOverviewDataReducer from './facilityOverview/facilityOverviewDataSlice';
import facilityDataReducer from './facility/facilityDataSlice';
import userSettingReducer from './user/userSlice';
import decarbReducer from './decarb/decarbSlice';
import projectReducer from './project/projectSlice';
import benchmarkReducer from './benchmark/benchmarkSlice';
import fileReducer from './file/fileSlice'
import businessUnitDataReducer from './businessUnit/businessUnitSlice';
import businessUnitOverviewReducer from './businessUnit/businessUnitOverviewSlice';
import roleReducer from "./role/roleSlice"
import evSlice from './ev/evSlice';
import bidPlanningDataReducer from "./bidPlanning/bidPlanningSlice";
import localFreightDataReducer from "./localFreight/localFreightSlice"
import fuelDataReducer from "./fuel/fuelSlice"
import trailerDataReducer  from './trailer/trailerSlice';
import divisionDataReducer from "./division/divisionSlice"
import divisionOverviewReducer from "./division/divisionOverviewSlice"
import ReportDataReducer from './report/reportSlice';
import evDashboardReducer from './evDashboard/evDashboardSlice';
import scopeOneDashboardReducer from './scope1/dashboard/dashboardSlice';
import scopeOneCommonDataReducer from './scope1/commonData/commonSlice';
// Array to hold middleware
const middleware: any = [];

// Add logger middleware to the array
if (process.env.REACT_APP_IS_DEV) {
  middleware.push(logger);
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    "loginDetails",
    "bucketLoginDetails",
    "bucketFileUpload",
    "unlinkTanentDto",
    "regionalId",
    "divisionId",
    "scopeType",
    "applicationTypeStatus",
    "userProfile"
  ]
}

const authReducer = persistReducer(persistConfig, authDataReducer)
// Configure the Redux store
const store = configureStore({
  reducer: {
    auth: authReducer,
    commonData: commonDataReducer,
    region: regionDataReducer,
    regionDash: dashRegionReducer,
    regionOverview: regionOverviewReducer,
    lane: laneDetailsReducer,
    home: homeReducer,
    sustain: sustainDataReducer,
    carrier: carrierDetailsReducer,
    facilityOverview: facilityOverviewDataReducer,
    facility: facilityDataReducer,
    user: userSettingReducer,
    project: projectReducer,
    decarb: decarbReducer,
    benchmark: benchmarkReducer,
    file: fileReducer,
    businessUnit: businessUnitDataReducer,
    businessUnitOverview: businessUnitOverviewReducer,
    ev: evSlice,
    evDashboard: evDashboardReducer,
    role: roleReducer,
    bidPlanning: bidPlanningDataReducer,
    localFreight: localFreightDataReducer,
    fuel: fuelDataReducer,
    trailer: trailerDataReducer,
    division: divisionDataReducer,
    divisionOverview: divisionOverviewReducer,
    laneReport: ReportDataReducer,
    scopeOneDashboard: scopeOneDashboardReducer,
    scopeOneCommonData: scopeOneCommonDataReducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    // Disable checks for immutability and serializability for improved performance
    immutableCheck: false,
    serializableCheck: false
  }).concat(middleware),
});

export const persistor = persistStore(store)


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: { auth: AuthState, dashboard: DashboardState, commonData: CommonState, region: RegionState, home: HomeState }
export type AppDispatch = typeof store.dispatch;

export default store;
