import { Navigate, Outlet, useLocation } from "react-router-dom";
import HeaderLayout from "../component/layouts/header";
import SidebarLayout from "../component/layouts/sidebar";
import { checkRolePermission, getLocalStorage, isCompanyEnable, getBaseUrl, isApplicationTypeChecked } from "utils";
import { Suspense } from "react";
import { BucketFileUpload } from "pages/bucket/fileUpload/BucketFileUpload";
import { useAppSelector, useAppDispatch } from "store/redux.hooks";
import { addUrl } from "../store/commonData/commonSlice";
import { applicationType, logoutPost, updateScopeType } from "store/auth/authDataSlice";
import ChatBot from "../pages/chatbot/ChatbotView";
import Spinner from 'component/spinner';
import { companySlug, scopeSlug } from "constant"
import { routeKey } from "constant/routeKey";

// Returns Is user is logged in or not
export const useAuth = () => {
  const userdata: any = getLocalStorage("loginDetails");
  return userdata?.token
    ? { loggedIn: true, userdata }
    : { loggedIn: false, userdata };
};
export const bucketUseAuth = () => {
  const userdata: any = getLocalStorage("bucketLoginDetails");
  return userdata?.token
    ? { loggedIn: true, userdata }
    : { loggedIn: false, userdata };
};
// Checks AuthRouters and redirects them to dashboar
export const AuthRouteCheck = ({ children, userDetails, scopeType, userProfile }: any) => {
  const isAuth = useAuth()

  if (!isAuth?.loggedIn) {
    return children;
  }

  else {
    if (userDetails?.data?.login_count === 1) {
      return <Navigate to="/settings" />;
    }
    else {

      return <Navigate to={getBaseUrl(userDetails?.data, scopeType, userProfile?.data)} />;
    }
  }
};

export const AuthBucketLoginRouteCheck = ({ children }: any) => {
  const isAuthBucket = bucketUseAuth();
  if (!isAuthBucket?.loggedIn) {
    return children;
  } else {
    return <Navigate to={"/bucket-add"} />;
  }
};

export const OnBoardingRouteCheck = ({ children, userDetails, scopeType, userProfile }: any) => {
  if (!isApplicationTypeChecked(userDetails?.data?.permissionsData || [], routeKey.AdministratorAccess) && !userProfile?.is_onboarded) {
    return <Navigate to={"/page-not-found"} />;
  } else {
    return children;
  }
};

// Checks Routes except AuthRouters and redirects them to respective route or Login page
export const ProtectedRouteCheck = ({ children }: any) => {
  const isAuth = useAuth();
  if (isAuth?.loggedIn) {
    return children;
  } else {
    return <Navigate to={"/"} />;
  }
};
export const ProtectedRouteBucket = () => {
  let isAuthBucket = bucketUseAuth();
  const isAuth = useAuth();
  if (!isAuth) {
    return <Navigate to="/bucket-login" />;
  } else if (checkRolePermission(isAuthBucket?.userdata, "blobUser")) {
    return <BucketFileUpload />;
  } else {
    // Return null or some default component in case no condition is met
    return <Navigate to={"/"} />;
  }
};
const ProtectedRoute = ({ application, isShowHeader, company, isOnBoard, userProfile }: any) => {
  const { loginDetails } = useAppSelector((state: any) => state.auth);
  const dispatch = useAppDispatch();
  const regionalLevel = getLocalStorage("regionalLevel")
  const location = useLocation();
  const { pathname } = location;
  const userHasPermission = true
  let companyHasPermission = true
  if (company) {
    companyHasPermission = company.includes(loginDetails?.data?.Company?.slug)
  }
  if (checkRolePermission(loginDetails?.data, "regionalManager") && regionalLevel === "") {
    dispatch(logoutPost())
    return <Navigate to="/" />;
  }
  if (!loginDetails?.data?.token) {
    return <Navigate to="/" />;
  }

  if (!userHasPermission) {
    return <Navigate to="/" />;
  }

  if (!companyHasPermission) {
    return <Navigate to="/" />;
  }

  dispatch(addUrl({ "url": pathname }))
  dispatch(applicationType(application === "admin"))

  dispatch(updateScopeType(scopeSlug.scope3))

  if (!checkRolePermission(loginDetails?.data, "blobUser")) {
    return (
      <section className="insight_top_wrapper">
        {application === "dashboard" ? <div className="mainDashboard ">
          <div className="DashboardWrapper w-100">
            {isShowHeader && <HeaderLayout />}

            <Suspense fallback={<Spinner spinnerClass='justify-content-center' />}>
              <div className="container-fluid g-0">
                <Outlet />
              </div>
            </Suspense>
          </div>
        </div>
          : <div className="mainDashboard position-relative">
            <SidebarLayout />
            <div className="DashboardWrapper">
              <HeaderLayout />
              <div className="dashboardScreen">
                <Suspense fallback={<Spinner spinnerClass='justify-content-center' />}>
                  <div className="container-fluid">
                    <Outlet />
                    {isCompanyEnable(loginDetails?.data, [companySlug?.pep]) && loginDetails?.data?.chatbot_access && application === "scope3" && <ChatBot />}
                  </div>
                </Suspense>
              </div>
            </div>
          </div>
        }
      </section>
    )
  } else {
    return null;
  }
};

export const Scope1ProtectedRoute = ({ application, isShowHeader, userProfile, company, isOnBoard }: any) => {
  const { loginDetails } = useAppSelector((state: any) => state.auth);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { pathname } = location;

  let companyHasPermission = true
  if (company) {
    companyHasPermission = company.includes(loginDetails?.data?.Company?.slug)
  }
 
  if (!loginDetails?.data?.token) {
    return <Navigate to="/" />;
  }

  if (!companyHasPermission) {
    return <Navigate to="/" />;
  }
  if(!isOnBoard && !userProfile?.data?.is_onboarded ) {
    return <Navigate to="/scope1/onboard" />
  }

  if (isOnBoard && userProfile?.data?.is_onboarded) {
    return <Navigate to="/" />
  }

  dispatch(addUrl({ "url": pathname }))
  dispatch(applicationType(application === "admin"))
  dispatch(updateScopeType(scopeSlug.scope1))

  if (!checkRolePermission(loginDetails?.data, "blobUser")) {
    return (
      <section className="insight_top_wrapper">
        {application === "dashboard" ? <div className="mainDashboard ">
          <div className="DashboardWrapper w-100">
            {isShowHeader && <HeaderLayout />}
            <Suspense fallback={<Spinner spinnerClass='justify-content-center' />}>
              <div className="container-fluid g-0">
                <Outlet />
              </div>
            </Suspense>
          </div>
        </div>
          : <div className="mainDashboard position-relative">
            <SidebarLayout />
            <div className="DashboardWrapper">
              <HeaderLayout />
              <div className="dashboardScreen">
                <Suspense fallback={<Spinner spinnerClass='justify-content-center' />}>
                  <div className="container-fluid">
                    <Outlet />
                  </div>
                </Suspense>
              </div>
            </div>
          </div>
        }
      </section>
    )
  } else {
    return null;
  }
};

ProtectedRoute.defaultProps = {
  application: "application"
}
export default ProtectedRoute;
